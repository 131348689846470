import * as React from "react";
import { useEffect } from 'react';
import Layout from "../components/layout"
import Seo from "../components/seo"
import './styles/redeem-thankyou.scss'
import { withTranslation } from 'react-i18next';
import { getLanguageDefault } from "../utils/Utils";

const ThankYouRedeemPage = ({t, i18n }) => {
  const isBrowser = () => typeof localStorage !== "undefined";
  const firstName = isBrowser() ? localStorage.getItem('donator_firstname') : '';
  var timeoutLanguage:any = null;

  useEffect(() => {
    if(timeoutLanguage) {
      clearTimeout(timeoutLanguage);
    }
    timeoutLanguage = setTimeout(() => {
      i18n.changeLanguage(getLanguageDefault().code.toLowerCase());
    }, 500);
  }, []);

  return (
    <Layout isShowAction={false} minHeight="100%">
      <Seo title="Thank you" />
      <div className="redeem-thankyou-container">
          <div className="redeem-thankyou-wrapper row">
            <h1>{t('dearText')} {firstName},</h1>
            <h1 className="w-dark-yellow-text">{t('descriptionTextThanks')}</h1>
            <h2>{t('descriptionRedeem')}</h2>
            <div className="content">
              <p>{t('noteRedeem')}</p> 
              <p>{t('noteRedeem1')}</p>
            </div>
        </div>
      </div>
    </Layout>
)}

export default withTranslation('blog')(ThankYouRedeemPage);